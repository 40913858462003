<template>
  <div class="box" @click="shows">
    <div class="header">
      <slot name="title"></slot>
    </div>
    <video
      :src="src"
      :controls="controls"
      :loop="loop"
      :muted="muted"
      class="video"
      ref="video"
    ></video>

    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: "https://api.singran.vip/assets/videos/1.mp4",
    },
    controls: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 400,
    },
    width: {
      type: Number,
      default: 600,
    },
  },
  setup(props, context) {
    console.log("子组件", props, context);
  },
  methods: {
    shows() {
      this.$refs.video.pause();
      this.$emit("videoshow");
    },
  },
});
</script>
<style scoped>
.box {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
  top: 0;
}
.header {
  font-size: 20px;
  color: #fff;
}
.video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 600px;
  height: 350px;
}
.footer {
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
}
</style>
