<template>
  <Factory />
</template>

<script>
import Factory from "../../../components/factory_washboard";

export default {
  name: "factory_washboard",
  components: {
    Factory,
  },
};
</script>